<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.loungeHeroImage.fields.umbracoFile.src + ')',
    }"
  >
    <LoungeForm :heroData="heroData" />
    <div
      :class="heroData.fields.loungeApplyGradient ? 'hero__gradient' : ''"
    ></div>
  </div>
</template>

<script>
import LoungeForm from "@/components/InnerForms/Lounge.vue";

export default {
  name: "HeroProduct",
  props: ["heroData"],
  components: {
    LoungeForm,
  },
};
</script>

<style lang="scss"></style>
