<template>
  <div
    class="hero-parking hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.backgroundImage.fields.umbracoFile.src + ')',
    }"
  >
    <div class="hero-parking__gradient"></div>
    <div class="inner center">
      <img
        :src="heroData.fields.image.fields.umbracoFile.src"
        :alt="heroData.fields.image.fields.alt"
        v-if="heroData.fields.image"
        class="hero-parking__logo"
      />
      <h1 class="hero-parking__title" v-if="!heroData.fields.image">
        <span></span>{{ heroData.fields.title }}
      </h1>

      <div class="grid">
        <div class="grid__half" v-if="heroData.fields.points.length">
          <ul class="hero-parking__usps">
            <li v-for="point in heroData.fields.points" :key="point">
              <div
                class="hero-parking__icon"
                :style="{
                  backgroundImage:
                    'url(' +
                    (point.fields.image
                      ? point.fields.image.fields.umbracoFile.src
                      : '') +
                    ')',
                }"
              ></div>
              <div>
                <strong>{{ point.fields.title }}</strong
                >{{ point.fields.subtitle }}
              </div>
            </li>
          </ul>
        </div>
        <div class="grid__half">
          <!-- Same widget as elsewhere except a few removals of code, so a new component will need to be made, not use the one globally -->
          <section class="hero-parking__form cf">
            <header>
              <h2>{{ heroData.fields.formTitle }}</h2>
            </header>

            <div class="form">
              <fieldset>
                <div class="cf">
                  <label for="entry">{{
                    this.labels.value.labelEntryDate
                  }}</label>

                  <div class="date">
                    <DatePicker
                      v-model="entryDate"
                      :min-date="new Date()"
                      :masks="{ input: this.displayFormat }"
                      class="datepicker"
                      :popover="{ visibility: 'focus' }"
                      @change="updateTeaserPrice"
                      is-required
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input :value="inputValue" v-on="inputEvents" />
                      </template>
                    </DatePicker>
                  </div>

                  <Select2
                    class="selectbox-time"
                    :options="optionsTime"
                    :settings="{
                      placeholder: this.labels.value.labelTime,
                    }"
                    v-model="entryTime"
                    @select="updateTeaserPrice"
                  />
                </div>
                <div class="cf">
                  <label for="exit">{{
                    this.labels.value.labelExitDate
                  }}</label>
                  <div class="date">
                    <DatePicker
                      v-model="exitDate"
                      :min-date="entryDate"
                      :masks="{ input: this.displayFormat }"
                      class="datepicker"
                      :popover="{ visibility: 'focus' }"
                      @change="updateTeaserPrice"
                      is-required
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input :value="inputValue" v-on="inputEvents" />
                      </template>
                    </DatePicker>
                  </div>

                  <Select2
                    class="selectbox-time"
                    :options="optionsTime"
                    :settings="{
                      placeholder: this.labels.value.labelTime,
                    }"
                    v-model="exitTime"
                    @select="updateTeaserPrice"
                  />
                </div>
                <div
                  :style="{
                    display: !heroData.fields
                      .suppressTerminalElementInBookingForm
                      ? 'block'
                      : 'none',
                  }"
                  class="airline cf"
                >
                  <label for="airline">{{
                    this.labels.value.labelAirline
                  }}</label>
                  <option
                    v-if="!heroData.fields.suppressTerminalElementInBookingForm"
                    id="airline"
                    value="-1"
                    style="display: none"
                    :selected="
                      heroData.fields.suppressTerminalElementInBookingForm
                    "
                  ></option>

                  <Select2
                    v-if="!heroData.fields.suppressTerminalElementInBookingForm"
                    :options="optionsAirline"
                    :settings="{
                      placeholder: this.labels.value.labelSelectAirline,
                    }"
                    v-model="airlineTerminal"
                    @select="updateTerminal"
                  />
                </div>
                <p v-if="teaserPrice">
                  {{
                    this.labels.value.labelPriceTeaser.replace(
                      "{0}",
                      this.currency + this.teaserPrice
                    )
                  }}
                </p>
                <div class="button">
                  <form
                    method="post"
                    novalidate="novalidate"
                    data-role="parking-form"
                    v-on:submit="submitForm"
                  >
                    <input
                      type="hidden"
                      value="1"
                      name="parkingDetailsSubmitted"
                    />
                    <input type="hidden" value="1" name="progressToNextStep" />
                    <input type="hidden" name="terminal" v-model="terminal" />
                    <input
                      type="hidden"
                      v-model="promoCode"
                      name="promocodes"
                    />
                    <input
                      type="hidden"
                      name="entryDate"
                      v-model="entryDateNormalised"
                    />
                    <input type="hidden" name="entryTime" v-model="entryTime" />
                    <input
                      type="hidden"
                      name="exitDate"
                      v-model="exitDateNormalised"
                    />
                    <input type="hidden" name="exitTime" v-model="exitTime" />
                    <input
                      type="submit"
                      class="btn btn--primary"
                      :value="this.labels.value.buttonBook"
                    />
                  </form>
                </div>
              </fieldset>
            </div>
          </section>
          <!--/.parking-widget -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div>


  </div> -->
</template>
<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";

export default {
  name: "HeroParkingFeatures",
  props: ["heroData"],
  components: {
    Select2,
    DatePicker,
  },
  mixins: [parkingForm],
  computed: {
    optionsTime() {
      return this.buildTimeslots();
    },
    optionsAirline() {
      return this.buildOptionsAirline();
    },
  },
  data() {
    return {
      promoCode: this.heroData.fields.internalPromoCode ?? "",
    };
  },
};
</script>

<style lang="scss">
@include breakpoint($tablet-only) {
  .swiper-container {
    height: auto;
  }
}

.hero-parking {
  background: {
    color: #000;
    size: cover;
    repeat: no-repeat;
  }

  @include breakpoint($tablet-only) {
    &.hero-slider__slide {
      background: {
        color: $grey-bg;
        position: 50% 0;
        size: auto 50rem;
      }
      height: auto;

      .inner {
        padding-top: 15rem;
        top: auto;
        transform: none;
      }
    }
  }

  &__gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: black;
    opacity: 0.35;
    height: 50rem;

    @include breakpoint($tablet) {
      height: 100%;
    }
  }

  &__logo {
    max-width: 100%;
    max-height: 120px;
    display: inline-block;
    margin-bottom: 30px;

    @include breakpoint($tablet) {
      max-width: 600px;
    }
  }

  .grid {
    display: flex;
    gap: 30px;
    justify-content: center;

    @include breakpoint($tablet-only) {
      flex-direction: column-reverse;
    }

    .grid__half {
      float: none;
      margin: 0;
    }
  }

  &__title {
    font-size: 2.8rem;
    margin-bottom: 30px;
    line-height: 1.2;
    color: $white;
    font-weight: $light;
    text-align: center;

    @include breakpoint($tablet-sml) {
      font-size: 3.6rem;
    }

    img {
      display: inline-block;
    }

    span {
      display: block;
      margin: 0 auto 10px auto;
      width: 64px;
      height: 64px;

      background: {
        position: 50% 50%;
        size: contain;
        repeat: no-repeat;
        image: url("~@/assets/images/parking-icon-white.png");
      }

      @include retina-js-img(
        "~@/assets/images/parking-icon-white",
        png,
        64px,
        64px
      );

      @include breakpoint($tablet-sml) {
        display: inline-block;
        vertical-align: middle;
        margin: 0 20px 0 0;
      }
    }
  }

  > .inner {
    max-width: 860px;
    text-align: center;
  }

  &__usps {
    font-size: 1.4rem;
    color: #fff;
    text-align: left;

    @include breakpoint($tablet-only) {
      color: $base-font-color;
      margin: 30px auto;
      max-width: 400px;
    }

    li {
      display: table;
      width: 100%;
      padding: 15px 0;
      line-height: 1.4;

      @include breakpoint($tablet) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    div {
      display: table-cell;
      vertical-align: middle;
    }

    div:not(.hero-parking__icon) {
      padding-left: 10px;
    }

    strong {
      font-size: 1.6rem;
      font-weight: $bold;
      color: $base-font-color;
      display: block;

      @include breakpoint($tablet) {
        color: $white;
        font-size: 2rem;
      }
    }
  }

  &__icon {
    width: 46px;

    background: {
      position: 50% 50%;
      repeat: no-repeat;
      size: contain;
    }
  }
  &__form {
    background: $white;
    float: none;
    max-width: 400px;
    padding: 30px 10px 20px;
    margin: 0 auto;
    text-align: center;

    @include breakpoint($tablet) {
      background: rgba(255, 255, 255, 0.14);
    }

    p {
      color: $white;
      font-size: 1.8rem;
      margin: 10px 0;
      font-weight: $light;

      @include breakpoint($desktop) {
        font-size: 2rem;
      }
    }

    h2 {
      font-size: 2.2rem;
      padding: 0;
      line-height: 1.2;
      background: none !important;
      color: $base-font-color;
      margin-bottom: 0;

      @include breakpoint($tablet) {
        color: $white;
      }
    }

    div {
      margin-bottom: 1.5rem;

      .airline div {
        margin-bottom: 0;
      }

      .date,
      .datepicker {
        margin-bottom: 0;
      }
    }

    fieldset {
      margin: 0;
      padding: 0;
      border: none;
    }

    @include breakpoint($tablet) {
      padding: 30px 20px 20px;
    }

    .form {
      width: auto;
      margin: 20px auto 0;
      text-align: left;

      label {
        color: $white;
        text-align: left;

        @include breakpoint($tablet-only) {
          color: $base-font-color;
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }

      input,
      label,
      select {
        vertical-align: middle;
      }

      .date {
        float: left;
        width: 55%;
      }

      .selectbox-time {
        width: 40% !important;
        float: right;
        text-align: left;
        margin-bottom: 0;

        .select2 {
          width: 100% !important;
          float: right;
          text-align: left;
        }
      }

      .airline {
        .select2-container {
          width: 100% !important;
        }
      }

      .button {
        text-align: center;
        margin-bottom: 0;

        input[type="button"] {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.vc-popover-content-wrapper {
  div {
    margin-bottom: 0;
  }
  .datepicker {
    float: none;
    width: auto;
  }
}
</style>
