<template>
  <div class="pinned-parking-sticky">
    <section class="parking-widget parking-widget--pinned cf">
      <header>
        <span class="pinned-parking__icon"></span>
        <h2 class="pinned-parking__title">
          {{ this.labels.value.labelPersistentParkingFormIntro }}
        </h2>
      </header>

      <div class="form form--inline" data-role="parking-form-wrapper">
        <fieldset>
          <div class="date">
            <label for="entry">{{ this.labels.value.labelEntryDate }}</label>

            <DatePicker
              v-model="entryDate"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              @change="this.updateTeaserPrice"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </DatePicker>

            <Select2
              class="selectbox-time"
              :options="optionsTime"
              :settings="{ placeholder: this.labels.value.labelTime }"
              v-model="entryTime"
              @select="updateTeaserPrice"
            />
          </div>
          <div class="date">
            <label for="exit">{{ this.labels.value.labelExitDate }}</label>

            <DatePicker
              v-model="exitDate"
              :min-date="entryDate"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              @change="this.updateTeaserPrice"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </DatePicker>

            <Select2
              class="selectbox-time"
              :options="optionsTime"
              :settings="{ placeholder: this.labels.value.labelTime }"
              v-model="exitTime"
              @select="updateTeaserPrice"
            />
          </div>
          <div
            :style="{
              display: !settings.suppressTerminalElement ? '' : 'none',
            }"
            class="terminal"
          >
            <label for="terminal">{{ this.labels.value.labelAirline }}</label>
            <option
              v-if="settings.suppressTerminalElement"
              id="terminal"
              style="display: none"
              value="-1"
            ></option>
            <Select2
              v-if="!settings.suppressTerminalElement"
              id="terminal"
              :options="optionsAirline"
              :settings="{ placeholder: this.labels.value.labelSelectAirline }"
              v-model="airlineTerminal"
              @select="updateTerminal"
            />
          </div>
          <div class="button">
            <form
              method="post"
              novalidate="novalidate"
              data-role="parking-form"
              v-on:submit="submitForm"
            >
              <input type="hidden" value="1" name="parkingDetailsSubmitted" />
              <input type="hidden" value="1" name="progressToNextStep" />
              <input type="hidden" name="terminal" v-model="terminal" />
              <input type="hidden" value="" name="promocodes" />
              <input
                type="hidden"
                name="entryDate"
                v-model="entryDateNormalised"
              />
              <input type="hidden" name="entryTime" v-model="entryTime" />
              <input
                type="hidden"
                name="exitDate"
                v-model="exitDateNormalised"
              />
              <input type="hidden" name="exitTime" v-model="exitTime" />
              <input
                type="submit"
                class="btn btn--primary"
                :value="this.labels.value.buttonBook"
              />
            </form>
          </div>
          <p class="price-from" v-if="teaserPrice">
            {{
              this.labels.value.labelPriceTeaser.replace(
                "{0}",
                this.currency + this.teaserPrice
              )
            }}
          </p>
        </fieldset>
      </div>
    </section>
    <!--/.parking-widget -->
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../mixins/parkingForm";

export default {
  name: "StickySalesBar",
  props: {
    settings: Object,
    selectedLanguage: String,
  },
  components: {
    Select2,
    DatePicker,
  },
  data() {
    return {
      entryDate: new Date(),
      exitDate: new Date(),
    };
  },
  mixins: [parkingForm],
  computed: {
    optionsTime() {
      return this.buildTimeslots();
    },
    optionsAirline() {
      return this.buildOptionsAirline();
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: () => {
      let scrollPos = window.scrollY;
      let stickyBar = document.querySelector(".pinned-parking-sticky");

      scrollPos > 600
        ? stickyBar.classList.add("pinned-parking-sticky--fixed")
        : stickyBar.classList.remove("pinned-parking-sticky--fixed");

      // if (scrollPos > 600) {
      //   stickyBar.classList.add("pinned-parking--fixed");
      // } else {
      //   stickyBar.classList.remove("pinned-parking--fixed");
      // }
    },
  },
};
</script>
