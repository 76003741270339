<template>
  <section id="carHireIframeSection">
    <div
      v-html="carHireIframeObjhtml"
      id="carHireIframe"
      class="carHireIframeSection"
    ></div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "CarHireIframeComponent",
  props: ["selectedLanguage", "componentData", "pageInfo"],
  data() {
    return {
      webpage: "",
      carHireIframeObjhtml: "",
    };
  },
  computed: {},
  mounted() {
    this.loadCarHireIframeObj();
  },
  methods: {
    loadCarHireIframeObj() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/CarHireIframeApi/CarHireIframeObj?` +
        `&iataCode=${process.env.VUE_APP_IATACODE}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.carHireIframeObjhtml = response.data.html;
          if (response.data.script != "") {
            let divScripts = document.getElementById("carHireIframe");
            let newScript = document.createElement("script");
            newScript.src = response.data.script;
            divScripts.appendChild(newScript);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/* Center the content */
.carHireIframeSection {
  height: 250px;
}
</style>
