<template>
  <div class="timeline-security-panel timeline-marker">
    <h3>
      <span>
        <img :src="componentData.fields.icon.fields.umbracoFile.src" />
      </span>
      {{ componentData.fields.title }}
    </h3>
    <div v-html="componentData.fields.body"></div>
    <a
      :href="componentData.fields.link.url"
      v-if="
        componentData.fields.link != null &&
        componentData.fields.link.url.length > 0
      "
      class="btn btn--primary"
      :target="componentData.fields.link.target"
      >{{ componentData.fields.link.name }}</a
    >
  </div>
</template>

<script>
export default {
  name: "TimelineSecurityPanel",
  props: ["componentData"],
  components: {},
};
</script>

<style lang="scss" scoped>
.timeline-security-panel {
  width: 100%;
  padding: 3rem;
  background-color: $skin-colour-primary;
  color: $white;
  font-size: 1.8rem;
  line-height: 1.5;

  h3 {
    color: $white !important;
    position: relative;
    align-items: center;
    display: flex;
    font-family: $futura;
    font-weight: 200;

    span {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: 0 1rem 0 0;
      border-radius: 50%;
      background: $skin-colour-primary-bright;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
    }
  }

  .btn {
    margin-top: 3rem;
  }
}
</style>
