<template>
  <div v-html="siteMapHTML" id="SiteMapHTML"></div>
</template>

<script>
import axios from "axios";

export default {
  name: "SiteMap",
  components: {},
  data() {
    return {
      siteMapHTML: "",
    };
  },
  mounted() {
    this.loadSiteMap();
  },
  methods: {
    loadSiteMap() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/SiteMapApi/CreateSiteMapHTML?url=/` +
        `&iataCode=${process.env.VUE_APP_IATACODE}`;
      console.log("Address :" + apiUrl);
      axios
        .get(apiUrl)
        .then((response) => {
          console.log("Response : " + response);
          this.siteMapHTML = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
#SiteMapHTML ul.level1 {
  font-size: 20px;
}
#SiteMapHTML ul.level2 {
  font-size: 16px;
  margin: 10px;
}
#SiteMapHTML ul.level3 {
  font-size: 12px;
  margin: 10px 0px 10px 20px;
}
#SiteMapHTML p {
  font-size: 10px;
  margin: 10px 0px 10px 20px;
}
</style>
