<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.image.fields.umbracoFile.src + ')',
    }"
  >
    <div class="inner center">
      <div class="hero__content">
        <h1 class="hero__heading">{{ heroData.fields.title }}</h1>
        <p>{{ heroData.fields.subtitle }}</p>
        <a
          class="btn btn--primary"
          v-if="heroData.fields.buttonText"
          :target="heroData.fields.link.target ? '_blank' : null"
          :href="heroData.fields.link.url"
          :title="heroData.fields.link.name"
          >{{ heroData.fields.buttonText }}</a
        >
      </div>
    </div>
    <div class="hero__gradient"></div>
  </div>
</template>

<script>
export default {
  name: "HeroPlain",
  props: ["heroData"],
};
</script>
