<template>
  <section id="hotelIframeSection">
    <div
      v-html="hotelIframeObjhtml"
      id="hotelIframe"
      class="hotelIframeSection"
    ></div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "HotelIframeComponent",
  props: ["selectedLanguage", "componentData", "pageInfo"],
  data() {
    return {
      webpage: "",
      hotelIframeObjhtml: "",
    };
  },
  computed: {},
  mounted() {
    this.loadHotelIframeObj();
  },
  methods: {
    loadHotelIframeObj() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/HotelIframeApi/HotelIframeObj?` +
        `&iataCode=${process.env.VUE_APP_IATACODE}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.hotelIframeObjhtml = response.data.html;
          if (response.data.script != "") {
            let divScripts = document.getElementById("hotelIframe");
            let newScript = document.createElement("script");
            newScript.src = response.data.script;
            newScript.async = true;
            divScripts.appendChild(newScript);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/* Center the content */
.carHireIframeSection {
  height: 250px;
}
</style>
