<template>
  <section class="lounge-widget">
    <div class="form form--inline">
      <fieldset>
        <div class="grid">
          <div class="grid__sixth">
            <label for="entryDate">{{
              this.labels.value.labelEntryDate
            }}</label>
            <DatePicker
              v-model="entryDate"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue || this.labels.value.labelDate"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>
          <div class="grid__sixth">
            <label for="entryTime">{{ this.labels.value.labelTime }}</label>
            <Select2
              data-field="hidden-arrival-hour"
              class="selectbox-time"
              :options="[
                '06:00 - 06:30',
                '07:00 - 07:30',
                '08:00 - 08:30',
                '09:00 - 09:30',
                '10:00 - 10:30',
                '11:00 - 11:30',
                '12:00 - 12:30',
                '13:00 - 13:30',
                '14:00 - 14:30',
                '15:00 - 15:30',
                '16:00 - 16:30',
                '17:00 - 17:30',
              ]"
              :settings="{
                placeholder: this.labels.value.labelTime,
              }"
              v-model="entryTime"
            />
          </div>

          <div class="grid__sixth">
            <label for="loungeAdults">Adults (12+)</label>
            <Select2
              name="Lounge_numAdults"
              data-field="hidden-adult"
              class="selectbox"
              :options="['1', '2', '3', '4', '5']"
              :settings="{
                placeholder: '0',
              }"
              v-model="loungeAdults"
            />
          </div>
          <div class="grid__sixth">
            <label for="loungeChild">Children (3-11)</label>
            <Select2
              name="Lounge_numChildren"
              data-field="hidden-children"
              class="selectbox"
              :options="['0', '1', '2', '3', '4', '5']"
              :settings="{
                placeholder: '0',
              }"
              v-model="loungeChild"
            />
          </div>

          <div class="grid__rest">
            <form
              @submit="PBBSubmitLoungeForm"
              :action="loungePinnedBookingBarSettings.fields.pBBLoungeFormLink"
              method="post"
              class="ng-pristine ng-valid"
              novalidate="novalidate"
            >
              <button type="submit" class="btn btn--primary" tabindex="0">
                {{ this.labels.value.buttonBook }}
              </button>
              <!-- Primary fields for the Lounge banner -->
              <input type="hidden" value="1" name="submitted" />
              <input
                type="hidden"
                name="arrivalDate"
                v-model="entryDateNormalised"
              />
              <input
                type="hidden"
                name="EscapeLounge_numAdults"
                v-model="loungeAdults"
              />
              <input
                type="hidden"
                name="EscapeLounge_numChildren"
                v-model="loungeChild"
              />
              <input
                type="hidden"
                name="AirportVisitDetails_arrival_hour"
                v-model="entryTime"
              />
            </form>
            <!-- commented out the Airport Services button for now as it might be needed at a later date -->
            <!-- <button
              class="btn btn--secondary"
              @click="redirectToAirportServices"
            >
              Airport services
            </button> -->
          </div>
        </div>
        <div class="grid">
          <div class="grid_full">
            <div
              v-if="!isValidResponse"
              class="booking-module__error"
              data-message="time-slot-not-available"
            >
              Sorry, no availability for your chosen timeslot.
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";
import dayjs from "dayjs";
import axios from "axios";

const LOCALE_CONFIG_MAPPING = {
  "en-US": {
    currency: "$",
    inputFormat: "MM/dd/yyyy",
    displayFormat: "MM/DD/YYYY",
  },
  "en-EU": {
    currency: "€",
    inputFormat: "dd/MM/yyyy",
    displayFormat: "DD/MM/YYYY",
  },
  "en-GB": {
    currency: "£",
    inputFormat: "dd/MM/yyyy",
    displayFormat: "DD/MM/YYYY",
  },
  // add more mappings as needed
};

export default {
  name: "StickyBookingBarLoungeForm",
  mixins: [parkingForm],
  props: {
    loungePinnedBookingBarSettings: Object,
  },
  data() {
    return {
      isValidResponse: false,
      entryDate: "",
      entryTime: "",
      loungeAdults: "1",
      loungeChild: "1",
    };
  },
  computed: {
    localeConfig() {
      return (
        LOCALE_CONFIG_MAPPING[
          this.loungePinnedBookingBarSettings.fields.globalLocale
        ] || LOCALE_CONFIG_MAPPING["en-US"]
      );
    },
    displayFormat() {
      return this.localeConfig.displayFormat;
    },
    entryDateNormalised: function () {
      return dayjs(this.entryDate).format(this.displayFormat);
    },
  },
  methods: {
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
    redirectToAirportServices() {
      window.location.href = "/at-the-airport/airport-services/";
    },
    PBBSubmitLoungeForm(event) {
      event.preventDefault();
      axios
        .get(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/AeroparkerBookingApi/GetAncillaryAvailability?arrivalDateTime=" +
            dayjs(this.entryDate).format("YYYY-MM-DD HH:mm") +
            "&iataCode=" +
            process.env.VUE_APP_IATACODE
        )
        .then((response) => {
          if (response.data) {
            this.isValidResponse = response.data;
            event.target.submit();
          } else {
            this.isValidResponse = response.data;
          }
        });
    },
  },
  components: {
    Select2,
    DatePicker,
  },
  mounted() {
    this.isValidResponse = true;
  },
};
</script>
