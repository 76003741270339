<template>
  <div class="book-hotels-inline" data-role="hotel-form-wrapper">
    <div class="grid">
      <div class="grid__full">
        <h2>{{ labels.value.hotelBookingTitle }}</h2>
        <label for="hotelDestination">{{
          labels.value.hotelBookingDestinationsLabel
        }}</label>
        <Select2
          :options="destinations"
          v-model="destination"
          :settings="select2Settings"
        />
      </div>
    </div>

    <div class="dates cf">
      <div class="col-date">
        <label for="entryDate">{{
          labels.value.hotelBookingCheckInLabel
        }}</label>
        <DatePicker
          :min-date="new Date()"
          class="datepicker"
          :popover="{ visibility: 'focus' }"
          is-required
          v-model="entryDate"
          :placeholder="new Date()"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input :value="inputValue" v-on="inputEvents" />
          </template>
        </DatePicker>
      </div>
      <div class="col-date">
        <label for="exitDate">{{
          labels.value.hotelBookingCheckOutLabel
        }}</label>
        <DatePicker
          v-model="exitDate"
          :min-date="entryDate"
          class="datepicker"
          :popover="{ visibility: 'focus' }"
          is-required
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input :value="inputValue" v-on="inputEvents" />
          </template>
        </DatePicker>
      </div>
    </div>

    <button
      @click="submitBookingForm()"
      type="submit"
      class="btn btn--primary"
      tabindex="0"
    >
      {{ labels.value.hotelBookingSubmitButtonText }}
    </button>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "HotelBooking",
  props: ["selectedLanguage", "componentData"],
  inject: ["labels"],
  created() {
    this.loadDestinations(); // Initial fetch of destinations
  },
  data() {
    return {
      rawDestinations: [],
      bookingUrl: "",
      entryDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
      exitDate: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD HH:mm"),
      destination: "",
    };
  },
  watch: {
    selectedLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadDestinations(); // Re-fetch destinations on language change
      }
    },
  },
  methods: {
    loadDestinations() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/DestinationApi/GetDestinationsLite?url=/destinations/&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          console.log("API Response:", response.data);
          this.rawDestinations = response.data.destinations || [];
        })
        .catch((error) => {
          console.log("No destinations received.");
          console.error(error);
          this.rawDestinations = [];
        });
    },
    submitBookingForm() {
      axios
        .get(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/HotelBookingApi/RetrieveHotelBooking?destination=" +
            this.destination +
            "&arrivalDateTime=" +
            dayjs(this.entryDate).format("YYYY-MM-DD HH:mm") +
            "&exitDateTime=" +
            dayjs(this.exitDate).format("YYYY-MM-DD HH:mm") +
            "&iataCode=" +
            process.env.VUE_APP_IATACODE
        )
        .then((response) => {
          this.bookingUrl = response.data;
          window.open(this.bookingUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    Select2,
    DatePicker,
  },
  computed: {
    destinations() {
      if (this.rawDestinations.length > 0) {
        return this.rawDestinations.map((el) => el.cityName);
      } else {
        return [
          {
            text: this.labels.value.hotelBookingNoDestinationsText,
            value: "",
          },
        ];
      }
    },
    select2Settings() {
      return {
        placeholder:
          this.destinations && this.destinations.length > 0
            ? this.destinations[0]
            : this.labels.value.hotelBookingNoDestinationsText,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.book-hotels-inline {
  background: #f4f7ff;
  border-radius: 12px;
  padding: 3rem 2rem;
  margin: 4rem 0;
}
.book-hotels-inline label {
  color: #213368 !important;
}

.typeset h2,
.typeset .flight-status__alert,
.typeset .item__heading {
  margin-bottom: 3rem;
}

.book-hotels-inline .grid:not(:last-of-type),
.book-hotels-inline .book-priority:not(:last-of-type),
.book-hotels-inline .book-testing:not(:last-of-type) {
  margin-bottom: 2rem;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  color: #213368;
  line-height: 48px;
  display: block;
  padding-left: 8px;
  padding-right: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
select {
  font-size: 1.8rem;
}

.btn--primary {
  background: #00a8e1;
  color: #fff;
  border-radius: 100px;
  font-size: 1.9rem;
  line-height: 1;
  display: inline-block;
  min-width: 16rem;
  border: 1px solid transparent;
  text-align: center;
  padding: 1.45rem 2.5rem;
  -webkit-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  cursor: pointer;
}
</style>
