<template>
  <div
    id="pinned-forms-bar"
    class="pinned-forms-bar"
    v-bind:class="
      pinnedBookingPageInfo.fields.pbbheader == true
        ? 'hidden'
        : 'pinned-parking'
    "
  >
    <div
      id="tabs"
      class="booking-module__tabs"
      v-bind:class="
        pinnedBookingPageInfo.fields.pbbheader == true
          ? ''
          : 'floating-booking-bar'
      "
    >
      <ul id="form-tabs" class="form-tabs">
        <li
          v-if="
            pinnedBookingPublicSiteSettings.fields
              .showParkingFormInBookingBanner
          "
        >
          <a
            v-bind:class="
              !pinnedBookingPageInfo.fields.pbbheader ? 'highlighted' : ''
            "
            href="javascript:void(0)"
            id="parkingTab"
            @click="
              this.showForm('parking', pinnedBookingPageInfo.fields.pbbheader)
            "
            ><SvgIcon name="parking" class="icon" />Parking</a
          >
        </li>
        <li
          v-if="
            pinnedBookingPublicSiteSettings.fields
              .showSecurityFormInBookingBanner
          "
        >
          <a
            href="javascript:void(0)"
            id="securityTab"
            @click="
              this.showForm('security', pinnedBookingPageInfo.fields.pbbheader)
            "
            ><SvgIcon name="insurance" class="icon" />Security</a
          >
        </li>
        <li
          v-if="
            pinnedBookingPublicSiteSettings.fields
              .showLoungesFormInBookingBanner
          "
        >
          <a
            href="javascript:void(0)"
            id="loungeTab"
            @click="
              this.showForm('lounge', pinnedBookingPageInfo.fields.pbbheader)
            "
            ><SvgIcon name="lounge" class="icon" />Lounge</a
          >
        </li>
        <li
          v-if="
            pinnedBookingPublicSiteSettings.fields.showHolidaysFormInBookingBar
          "
        >
          <a
            href="javascript:void(0)"
            id="holidaysTab"
            @click="
              this.showForm('holidays', pinnedBookingPageInfo.fields.pbbheader)
            "
          >
            <SvgIcon name="plane" class="icon" />Book a Holiday</a
          >
        </li>
        <li
          v-if="
            pinnedBookingPublicSiteSettings.fields
              .showCarRentalFormInBookingBanner
          "
        >
          <a
            href="javascript:void(0)"
            id="carrentalTab"
            @click="
              this.showForm('carrental', pinnedBookingPageInfo.fields.pbbheader)
            "
            ><SvgIcon name="car" class="icon" />Car Rental</a
          >
        </li>
        <li
          v-if="
            pinnedBookingPublicSiteSettings.fields.showTaxisFormInBookingBanner
          "
        >
          <a
            href="javascript:void(0)"
            id="taxisTab"
            @click="
              this.showForm('taxis', pinnedBookingPageInfo.fields.pbbheader)
            "
            ><SvgIcon name="car" class="icon" />Taxis</a
          >
        </li>
      </ul>

      <div id="form-content" class="form-content">
        <article
          id="parking"
          v-bind:class="!pinnedBookingPageInfo.fields.pbbheader ? '' : 'hidden'"
        >
          <StickyBookingParkingWidget
            :stickyBookingParkingWidgetSettings="pinnedBookingSettings"
            :selectedLanguage="pinnedBookingSelectedLanguage"
            :widgetBookingPageInfo="pinnedBookingPageInfo"
          />
        </article>

        <article id="security" class="hidden">
          <StickyBookingBarSecurityForm
            :securityPinnedBookingBarSettings="pinnedBookingPublicSiteSettings"
          />
        </article>

        <article id="lounge" class="hidden">
          <StickyBookingBarLoungeForm
            :loungePinnedBookingBarSettings="pinnedBookingPublicSiteSettings"
          />
        </article>

        <article id="holidays" class="hidden">
          <StickyBookingBarHolidaysForm></StickyBookingBarHolidaysForm>
        </article>

        <article id="carrental" class="hidden">
          <StickyBookingBarCarForm />
        </article>

        <article id="taxis" class="hidden">
          <StickyBookingBarBookTaxiForm />
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/Icon";
import StickyBookingParkingWidget from "@/components/StickyBookingBarForms/StickyBookingParkingWidget.vue";
import StickyBookingBarSecurityForm from "@/components/StickyBookingBarForms/StickyBookingBarSecurityForm.vue";
import StickyBookingBarLoungeForm from "@/components/StickyBookingBarForms/StickyBookingBarLoungeForm.vue";
import StickyBookingBarCarForm from "@/components/StickyBookingBarForms/StickyBookingBarCarForm.vue";
import StickyBookingBarBookTaxiForm from "@/components/StickyBookingBarForms/StickyBookingBarBookTaxiForm.vue";
import StickyBookingBarHolidaysForm from "@/components/StickyBookingBarForms/StickyBookingBarHolidaysForm.vue";

export default {
  name: "StickyFormBookingBar",
  props: {
    pinnedBookingSettings: Object,
    pinnedBookingSelectedLanguage: String,
    pinnedBookingPublicSiteSettings: Object,
    pinnedBookingPageInfo: Object,
  },
  inject: ["labels"],
  components: {
    SvgIcon,
    StickyBookingParkingWidget,
    StickyBookingBarSecurityForm,
    StickyBookingBarLoungeForm,
    StickyBookingBarCarForm,
    StickyBookingBarBookTaxiForm,
    StickyBookingBarHolidaysForm,
  },
  methods: {
    PBBHandleScroll: (e, PBBool) => {
      let scrollPos = window.scrollY;
      let stickyBarTabs = document.getElementById("tabs");
      let pinnedBookingBar = document.getElementById("pinned-forms-bar");
      var forms = document.querySelectorAll("#form-content article");
      var tabs = document.querySelectorAll("#form-tabs a");

      let startingOpenTab = document.getElementById("parkingTab");
      let securityTab = document.getElementById("securityTab");
      let loungeTab = document.getElementById("loungeTab");
      let carrentalTab = document.getElementById("carrentalTab");
      let taxisTab = document.getElementById("taxisTab");
      let holidaysTab = document.getElementById("holidaysTab");

      let startingOpenForm = document.getElementById("parking");
      let securityForm = document.getElementById("security");
      let loungeForm = document.getElementById("lounge");
      let carRentalForm = document.getElementById("carrental");
      let taxiForm = document.getElementById("taxis");
      let holidaysForm = document.getElementById("holidays");

      const isScrollPosBelowThreshold = scrollPos < 600;
      const allFormsAreHidden =
        securityForm.classList.contains("hidden") &&
        loungeForm.classList.contains("hidden") &&
        carRentalForm.classList.contains("hidden") &&
        taxiForm.classList.contains("hidden") &&
        holidaysForm.classList.contains("hidden");

      if (isScrollPosBelowThreshold && allFormsAreHidden) {
        if (!PBBool) {
          startingOpenTab.classList.add("highlighted");
          startingOpenForm.classList.remove("hidden");
        } else {
          if (PBBool == false) {
            startingOpenTab.classList.remove("highlighted");
            startingOpenForm.classList.add("hidden");
          }
        }
      }

      if (scrollPos > 600) {
        pinnedBookingBar.classList.add("pinned-parking");
        pinnedBookingBar.classList.add("pinned-parking--fixed");
        stickyBarTabs.classList.remove("floating-booking-bar");
        stickyBarTabs.classList.add("booking-module__tabs");
        if (PBBool == false && scrollPos >= 700 && scrollPos <= 750) {
          if (startingOpenTab) {
            startingOpenTab.classList.remove("highlighted");
          }
          if (securityTab) {
            securityTab.classList.remove("highlighted");
          }
          if (loungeTab) {
            loungeTab.classList.remove("highlighted");
          }
          if (carrentalTab) {
            carrentalTab.classList.remove("highlighted");
          }
          if (taxisTab) {
            taxisTab.classList.remove("highlighted");
          }
          if (holidaysTab) {
            holidaysTab.classList.remove("highlighted");
          }

          forms.forEach(function (form) {
            form.classList.add("hidden");
          });
        }
      } else {
        if (PBBool == true) {
          pinnedBookingBar.classList.add("hidden");
          pinnedBookingBar.classList.remove("pinned-parking--fixed");
          pinnedBookingBar.classList.remove("pinned-parking");
          stickyBarTabs.classList.remove("floating-booking-bar");

          forms.forEach(function (form) {
            form.classList.add("hidden");
          });

          tabs.forEach(function (form) {
            form.classList.remove("highlighted");
          });
        } else {
          pinnedBookingBar.classList.remove("pinned-parking--fixed");
          stickyBarTabs.classList.add("floating-booking-bar");
        }
      }
    },
    showForm(tabId, PBBool) {
      let scrollPos = window.scrollY;
      var forms = document.querySelectorAll("#form-content article");
      var tabs = document.querySelectorAll("#form-tabs a");
      var selectedForm = document.getElementById(tabId);
      var selectedTab = document.getElementById(tabId + "Tab");

      forms.forEach(function (form) {
        if ((PBBool && scrollPos < 0) || (!PBBool && scrollPos < 600)) {
          if (!form.classList.contains("hidden")) {
            form.classList.add("hidden");
          }
        }
      });

      forms.forEach(function (form) {
        if (form.id != tabId) {
          form.classList.add("hidden");
        }
      });

      tabs.forEach(function (tab) {
        if (tab.id != tabId + "Tab") {
          tab.classList.remove("highlighted");
        }
      });

      if (selectedForm.classList.contains("hidden")) {
        selectedForm.classList.remove("hidden");
        selectedTab.classList.add("highlighted");
      } else {
        selectedForm.classList.add("hidden");
        selectedTab.classList.remove("highlighted");
      }
    },
  },
  created() {
    window.addEventListener("scroll", (e) => {
      this.PBBHandleScroll(e, this.pinnedBookingPageInfo.fields.pbbheader);
    });
  },
  unmounted() {
    window.removeEventListener("scroll", (e) => {
      this.PBBHandleScroll(e, this.pinnedBookingPageInfo.fields.pbbheader);
    });
  },
};
</script>

<style lang="scss">
.floating-booking-bar {
  position: relative;
  top: 42rem;
}

.pinned-forms-bar {
  height: 0;
  display: none;

  @include breakpoint($desktop-sml) {
    display: block;
  }

  .grid {
    & > div {
      float: left !important;
      clear: none !important;
      margin-right: 10px !important;
      min-width: 70px;
    }

    .grid__half {
      & > div {
        float: left !important;
        clear: none !important;
        margin-right: 10px !important;
      }
    }

    .grid__sixth {
      width: calc(99.9% * 1 / 6 - (30px - 30px * 1 / 6));
    }

    .grid__third {
      width: calc(99.9% * 1 / 4 - (30px - 30px * 1 / 4));

      @include breakpoint($desktop) {
        width: calc(99.9% * 1 / 3 - (30px - 30px * 1 / 3));
      }
    }

    .grid__rest {
      display: flex;
      margin-top: 4rem;
      margin-left: auto;

      @include breakpoint($desktop) {
        margin-left: 20px;
      }
    }
  }

  label {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 10px;
  }

  .form-content {
    position: relative;
  }

  .btn {
    transition: none !important;
    padding: 1.45rem 1.5rem;

    @include breakpoint($desktop) {
      padding: 1.45rem 2.5rem;
    }
  }

  .btn--secondary {
    margin-left: 1rem;
  }

  .date-time {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    &.long-label {
      .col-time-mins {
        label {
          display: none;
        }
      }
    }

    & > div {
      width: 50%;
    }
  }

  &.pinned-parking--fixed {
    padding: 0;
    height: 51px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    animation: inpage-search-slide 500ms 1;
    z-index: 3;
    border-bottom: 0.5px solid $skin-colour-primary;

    @include breakpoint($desktop-sml) {
      display: block !important;
    }

    article {
      max-width: 114rem;
      margin: 0 auto;
    }

    .booking-module__tabs {
      max-width: 100%;

      .form-tabs {
        justify-content: center;
        gap: 0;
      }
    }

    .form-content {
      border-radius: 0;
      box-shadow: none;
      padding: 0;

      article {
        padding: 3rem 3rem 4rem;
      }
    }

    .form-tabs {
      background-color: #fff;

      li {
        border-left: 0.5px solid $skin-colour-primary;

        &:last-child {
          border-right: 0.5px solid $skin-colour-primary;
        }

        a {
          background: transparent;
          color: $skin-colour-primary;
          box-shadow: none;

          &:hover {
            svg {
              color: $skin-colour-primary-bright;
            }
          }

          svg {
            color: $skin-colour-primary;
          }

          &.highlighted {
            background: $skin-colour-primary;
            color: #fff;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .form-content {
    background: $skin-colour-primary;
    padding: 3rem 3rem 4rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .carrental-widget,
    .lounge-widget,
    .taxi-widget,
    .parking-form-widget,
    .security-widget {
      header {
        display: none;
      }
      fieldset {
        display: table;
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;

        &.buttons {
          margin-top: 20px;
        }
      }
    }

    .taxi-widget {
      .btn-booktaxi {
        float: right;
        margin-top: 3rem;

        margin-right: 29%;

        @include breakpoint($desktop) {
          margin-right: 11.5%;
        }
      }
    }
  }
}

.hidden {
  display: none;
}

.booking-module__tabs {
  max-width: 114rem;
  width: 100%;
  z-index: 10;
  margin: 0 auto;

  ul {
    display: flex;
    width: 100%;
    gap: 0.4rem;

    li {
      a {
        justify-content: center;
        align-items: center;
        padding: 1.8rem 2.5rem;
        display: flex;
        max-height: 50px;
        background: #fff;
        color: #000;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-size: 1.5rem;
        text-decoration: none;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        white-space: nowrap;
        cursor: pointer;

        svg {
          color: #a7adc3;
          margin-right: 10px;
        }

        &:hover {
          background: #939393;
          color: #ffffff;

          svg {
            color: #fff;
          }
        }
      }

      .highlighted {
        background: $skin-colour-primary;
        color: #fff;

        &:hover {
          background: $skin-colour-primary;
        }

        svg {
          color: #fff;
        }
      }
    }
  }
}
</style>
