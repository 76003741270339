<template>
  <section>
    <!-- <h2>{{ componentData.fields.title }}</h2> -->

    <!-- <div class="image-block__image" :style="{backgroundImage:'url(' + item.fields.image.fields.umbracoFile.src +')', }"/> -->

    <section
      class="promo-block"
      :class="this.variantClass"
      :aria-label="componentData.fields.backgroundImage.fields.alt"
      :style="{
        backgroundImage:
          'url(' +
          componentData.fields.backgroundImage.fields.umbracoFile.src +
          ')',
      }"
    >
      <div class="promo-block__logo">
        <img
          :src="componentData.fields.image.fields.umbracoFile.src"
          :alt="componentData.fields.image.fields.alt"
          v-if="componentData.fields.image"
        />
      </div>
      <div class="inner cf">
        <div class="promo-block__content">
          <header>
            <h3 class="pretitle">{{ componentData.fields.subtitle }}</h3>
            <h2 class="promo-block__heading">
              {{ componentData.fields.title }}
            </h2>
          </header>

          <div v-html="componentData.fields.body"></div>

          <ul
            class="promo-block__content-btns"
            v-if="componentData.fields.graphicalLinks"
          >
            <li
              v-for="item in componentData.fields.graphicalLinks"
              :key="item.id"
            >
              <a
                :href="item.fields.link.url"
                :title="item.fields.link.name"
                :class="{ 'btn btn--primary': !item.fields.image }"
                :target="item.fields.link.target ? '_blank' : null"
              >
                <img
                  :src="item.fields.image.fields.umbracoFile.src"
                  :alt="item.fields.image.fields.alt"
                  v-if="item.fields.image"
                />
                <span v-else>{{ item.fields.link.name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--/.inner -->
      <div class="promo-block__gradient"></div>
    </section>
  </section>
</template>

<script>
export default {
  name: "PromoBlock",
  props: ["componentData"],
  computed: {
    variantClass: function () {
      if (this.componentData.fields.alignment === "Left") {
        return "promo-block--flip";
      } else if (this.componentData.fields.alignment === "Center") {
        return "promo-block--full";
      } else {
        return "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.promo-block {
  position: relative;

  background: {
    repeat: no-repeat;
    position: center bottom;
    size: cover;
    color: $skin-colour-primary;
  }

  > .inner {
    position: relative;
    z-index: 1;
  }

  .pretitle {
    color: $promo-module-pretitle;
  }

  &__heading {
    color: $promo-module-color;
    font-size: 3.2rem;

    @include breakpoint($desktop-sml) {
      font-size: 4.6rem;
    }
  }

  &__content {
    padding: 4rem $grid-padding;
    width: 100%;

    @include breakpoint($tablet-sml) {
      width: 50%;
      padding: 6rem $grid-padding;
    }

    .download-app {
      display: inline-block;
      margin-right: 1.5rem;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include breakpoint($responsive-nav) {
        margin-bottom: 0;
      }

      i {
        display: inline-block;
        height: 44px;
        width: 150px;
        background-size: contain;
      }

      img {
        display: block;
      }
    }

    .icon-download {
      vertical-align: top;
    }

    &-btns {
      text-align: left;
      margin-left: -1rem;

      li {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin: 0 1rem 1rem;
      }

      a {
        display: inline-block;

        .content__left & {
          color: white !important;
        }
      }

      i,
      img {
        max-width: 13rem;
        max-height: 3.5rem;

        @include breakpoint($desktop) {
          max-width: 15rem;
          max-height: 4rem;
        }
      }
    }
  }

  &__logo {
    position: relative;
    z-index: 1;
    padding: 4rem 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      @include breakpoint($desktop-sml) {
        max-width: 45rem;
      }
    }

    @include breakpoint($tablet-sml) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      width: 50%;
      padding: 0 2rem;
    }
  }

  .btn {
    min-width: 25rem;
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient($gradient);
    opacity: 0.9;

    @include breakpoint($tablet-sml) {
      width: 50%;
    }
  }

  p {
    color: $promo-module-color;
    margin-bottom: 3rem;
  }

  &--flip {
    .promo-block__gradient {
      @include breakpoint($tablet-sml) {
        left: auto;
        right: 0;
      }
    }

    .promo-block__content {
      float: right;
    }

    .promo-block__logo {
      @include breakpoint($tablet-sml) {
        right: auto;
        left: 0;
      }
    }
  }

  &--full {
    .promo-block__gradient {
      @include breakpoint($tablet-sml) {
        width: 100%;
      }
    }

    .promo-block__content {
      display: inline-block;
      max-width: 56rem;
      width: 100%;
      margin: 0 auto;
    }

    .promo-block__logo {
      @include breakpoint($tablet-sml) {
        padding-top: 4rem;
        position: relative;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: auto;

        + .inner .promo-block__content {
          padding-top: 3rem;

          p {
            margin-bottom: 3rem;
          }
        }
      }
    }

    .promo-block__content-btns {
      text-align: center;
    }

    @include breakpoint($tablet-sml) {
      text-align: center;

      p {
        font-size: 2.2rem;
        margin-bottom: 5rem;
      }
    }
  }
}

// In IE and older browsers that do not use Clip Path
.no-clip-path-shapes {
  .promo-block {
    &__gradient {
      @include breakpoint($tablet-sml) {
        width: 60%;
      }
    }
  }

  .promo-block__content {
    @include breakpoint($tablet-sml) {
      padding: 6rem 4rem 6rem $grid-padding;
    }
  }

  .promo-block--flip {
    .promo-block__gradient {
      @include breakpoint($tablet-sml) {
        width: 60%;
        left: auto;
        right: 0;
      }
    }

    .promo-block__content {
      @include breakpoint($tablet-sml) {
        padding: 6rem $grid-padding 6rem 4rem;
      }
    }
  }

  .promo-block--full {
    .promo-block__gradient {
      @include breakpoint($tablet-sml) {
        width: 100%;
      }
    }

    .promo-block__content {
      @include breakpoint($tablet-sml) {
        padding: 6rem $grid-padding;
      }
    }
  }
}
</style>
