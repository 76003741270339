<template>
  <section class="panel-padding">
    <div class="inner">
      <div class="retail-listing-filter">
        <ul class="retail-listing-filter__tabs">
          <li class="retail-listing-filter__tabs__tab">
            <button
              class="btn btn--squared"
              :class="{ active: activeTab === 0 }"
              @click="selectEverything(0)"
            >
              {{ filters.split(", ")[0] }}
            </button>
          </li>
          <li class="retail-listing-filter__tabs__tab">
            <button
              class="btn btn--squared"
              :class="{ active: activeTab === 1 }"
              @click="selectOnlyShops(1)"
            >
              {{ filters.split(", ")[1] }}
            </button>
          </li>
          <li class="retail-listing-filter__tabs__tab">
            <button
              class="btn btn--squared"
              :class="{ active: activeTab === 2 }"
              @click="selectOnlyRestaurants(2)"
            >
              {{ filters.split(", ")[2] }}
            </button>
          </li>
        </ul>
        <Select2
          class="retail-listing-filter__select"
          :options="optionsArray"
          :placeholder="optionsArray[0]"
          :settings="{ dropdownCssClass: 'yyy', containerCssClass: 'ooo' }"
          @select="selectSecurityType($event)"
        >
        </Select2>
      </div>

      <div class="retail-listing">
        <!-- Loop through the retailers array and display each retailer's information -->
        <a
          class="retail-listing__item"
          :href="retailer.url"
          v-for="retailer in retailers"
          :key="retailer.id"
        >
          <img
            class="retail-listing__item__image"
            :src="retailer.retailerImageUrl"
            :alt="retailer.name"
          />
          <div class="retail-listing__item__body">
            <h3 class="big-text">{{ retailer.name }}</h3>
            <!-- <p v-if="retailer.retailerType">{{ retailer.retailerType }}</p> -->
            <p v-if="retailer.retailerTypes">
              {{ retailer.retailerTypes.join(", ") }}
            </p>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";

export default {
  name: "RetailerListing",
  props: ["selectedLanguage", "componentData"],

  data() {
    return {
      retailers: [],
      webpage: "",
      filters: "",
      options: "",
      selectedOption: 0,
      selectedSecurity: 0,
      activeTab: 0,
    };
  },
  computed: {
    optionsArray() {
      return this.options.split(", ");
    },
    filteredValue() {
      return this.componentData.fields.filter;
    },
  },
  mounted() {
    this.loadRetailers();

    if (this.componentData.fields.filter === "Shops") {
      this.activeTab = 1;
    } else if (this.componentData.fields.filter === "Restaurants") {
      this.activeTab = 2;
    } else {
      this.activeTab = 0;
    }
  },

  watch: {
    selectedLanguage: "loadRetailers",
  },

  methods: {
    applyFilter() {
      this.retailers = this.originalRetailers;

      if (this.selectedOption != 0) {
        if (this.selectedOption == 1) {
          this.retailers = this.retailers.filter(
            (el) => el.retailerCategory == "Shop"
          );
        } else {
          this.retailers = this.retailers.filter(
            (el) => el.retailerCategory == "Restaurant"
          );
        }
      }

      //Really ugly way of mapping security filters to the retailers. Cant think of an alternative to support langauge swapping without it being hardcoded
      if (this.selectedSecurity === this.options.split(",")[1].trim()) {
        this.retailers = this.retailers.filter(
          (el) => el.beforeSecurity === true
        );
      } else if (this.selectedSecurity === this.options.split(",")[2].trim()) {
        this.retailers = this.retailers.filter(
          (el) => el.afterSecurity === true
        );
      }
    },
    selectSecurityType(item) {
      this.selectedSecurity = item.id;
      this.applyFilter();
    },
    selectEverything(index) {
      this.activeTab = index;
      this.selectedOption = index;
      this.applyFilter();
    },
    selectOnlyShops(index) {
      this.activeTab = index;
      this.selectedOption = index;
      this.applyFilter();
    },
    selectOnlyRestaurants(index) {
      this.activeTab = index;
      this.selectedOption = index;
      this.applyFilter();
      return this.retailers;
    },
    loadRetailers() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/RetailerApi/GetRetailersLite?url=${this.webpage}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.originalRetailers = response.data.retailers;
          this.filters = response.data.filters.categoryFilters;
          this.options = response.data.filters.securityFilters;
          this.retailers = this.originalRetailers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    Select2,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
section {
  background: #f2f3f6;
}

.retail-listing-filter {
  display: flex;
  flex-direction: column;

  @include breakpoint($tablet-sml) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include breakpoint($desktop) {
      margin-bottom: 0;
      flex-direction: row;
    }

    &__tab {
      border: 1px solid $keyline;
      border-top: none;

      &:first-child {
        border-top: 1px solid $keyline;
      }

      @include breakpoint($desktop) {
        border: 1px solid $keyline;
        border-left: none;

        &:first-child {
          border-left: 1px solid $keyline;
        }
      }

      .btn {
        color: $btn-primary;
        width: 100%;
        background-color: white;

        &.active {
          background: $btn-primary;
          color: $btn-primary-color;
        }
      }
    }
  }

  select {
    color: #213368;
    line-height: 48px;
  }
  .retail-listing-filter__select {
    @include breakpoint($desktop) {
      width: 20rem !important;
    }
  }
}

.retail-listing {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  @include breakpoint($tablet-sml) {
    margin-top: 4rem;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    border: 1px solid $keyline;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-decoration: none;

    @include breakpoint($tablet-sml) {
      width: calc(50% - 1rem);
    }

    @include breakpoint($tablet) {
      width: calc(33.33% - 1.34rem);
    }

    @include breakpoint($desktop) {
      width: calc(25% - 1.5rem);
    }

    &__image {
      aspect-ratio: 1.75/1;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }

    &__body {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 2rem;
      justify-content: space-between;
      padding: 2rem;
      background-color: white;

      .big-text {
        font-size: 1.9rem;
        line-height: 1.42;
        font-weight: 400;
        color: #213368;

        a:focus &,
        a:hover & {
          text-decoration: underline;
        }
      }

      p {
        color: #9099b3;
        margin: 0;
        font-size: 1.5rem;
      }
    }
  }
}
</style>
