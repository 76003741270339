<template>
  <section class="icon-list panel-padding">
    <div class="inner">
      <h2 class="heading">{{ componentData.fields.title }}</h2>
      <div class="icon-list__swiper-container">
        <swiper
          :allow-touch-move="true"
          :breakpoints="{
            600: { allowTouchMove: true, slidesPerView: 3 },
            767: { allowTouchMove: true, slidesPerView: 4 },
            900: { allowTouchMove: true, slidesPerView: 5 },
            1024: { allowTouchMove: false, slidesPerView: 5 },
          }"
          :loop="false"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
          :pagination="pagination"
          :slides-per-view="2"
        >
          <swiper-slide
            class="icon-list__item"
            v-for="(linkItem, index) in componentData.fields.links"
            :key="index"
          >
            <a
              class="icon-list__item__link"
              :href="linkItem.fields.link.url"
              :title="linkItem.fields.link.name"
              :target="linkItem.fields.link.target"
            >
              <div class="icon-list__item__icon">
                <img
                  class="icon-list__item__icon__image"
                  :src="linkItem.fields.image.fields.umbracoFile.src"
                  :alt="linkItem.fields.image.fields.alt"
                />
              </div>
              <p class="icon-list__item__text">
                {{ linkItem.fields.link.name }}
              </p>
            </a>
          </swiper-slide>
        </swiper>
        <div
          v-if="componentData.fields.links.length > 5"
          class="swiper-button-next"
          @click="($event) => next()"
        ></div>
        <div
          v-if="componentData.fields.links.length > 5"
          class="swiper-button-prev"
          @click="($event) => prev()"
        ></div>
      </div>
      <div class="icon-list__pagination"></div>
    </div>
  </section>
</template>

<script>
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "@/assets/scss/plugins/swiper/swiper.scss";
import "@/assets/scss/plugins/swiper/components/navigation/navigation.scss";
import "@/assets/scss/plugins/swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination, A11y]);

export default {
  name: "IconCallToActionList",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      pagination: {
        el: ".icon-list__pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },
      },
      modules: [Pagination],
    };
  },
  props: ["componentData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.icon-list {
  margin: 0 auto;
  padding: 0;
  text-align: center;

  &__swiper-container {
    position: relative;

    @include breakpoint($desktop-sml) {
      padding: 0 3rem;
    }
  }

  .swiper-container::after {
    background-color: white;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    z-index: 1;
  }

  .swiper-slide {
    height: auto;
  }

  &__item {
    border-right: 1px solid #dedede;
    padding: 1.5rem 2rem;

    &:last-of-type {
      border-right: none;
    }

    &__icon {
      height: 6rem;
      position: relative;

      &__image {
        left: 50%;
        max-height: 5rem;
        max-width: 5rem;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    &__link {
      min-height: 8rem;
      text-decoration: none;
    }

    &__text {
      margin: 0;
      padding: 0 0.5rem;
    }
  }

  &__pagination {
    margin-top: 3rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: $links;
  }

  .swiper-pagination-bullet {
    height: 1rem;
    margin: 0 0.75rem;
    width: 1rem;

    &-active {
      background: $links;
    }
  }
}
</style>
