<template>
  <section class="security-widget">
    <div class="form form--inline">
      <fieldset>
        <div class="grid">
          <div class="grid__sixth">
            <label for="entry">{{ this.labels.value.labelEntryDate }}</label>
            <DatePicker
              v-model="entryDate"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
              :v-model="arrivalDate"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue || this.labels.value.labelDate"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>
          <div class="grid__sixth">
            <label for="exit">{{ this.labels.value.labelTime }}</label>
            <Select2
              name="AirportVisitDetails_arrival_hour"
              data-field="hidden-arrival-hour"
              class="selectbox-time"
              :options="[
                '06:00 - 06:30',
                '07:00 - 07:30',
                '08:00 - 08:30',
                '09:00 - 09:30',
                '10:00 - 10:30',
                '11:00 - 11:30',
                '12:00 - 12:30',
                '13:00 - 13:30',
                '14:00 - 14:30',
                '15:00 - 15:30',
                '16:00 - 16:30',
                '17:00 - 17:30',
              ]"
              :settings="{
                placeholder: this.labels.value.labelTime,
              }"
              v-model="entryTime"
            />
          </div>
          <div class="grid__sixth">
            <label for="loungeAdults">Adults (12+)</label>
            <Select2
              name="Lounge_numAdults"
              data-field="hidden-adult"
              class="selectbox"
              :options="['1', '2', '3', '4', '5', '6']"
              :settings="{
                placeholder: '0',
              }"
              v-model="loungeAdults"
            />
          </div>
          <div class="grid__rest">
            <form
              @submit="PBBSubmitSecurityForm"
              :action="
                securityPinnedBookingBarSettings.fields.pBBSecurityFormLik
              "
              method="post"
              target="_blank"
              class="ng-pristine ng-valid"
            >
              <button type="submit" class="btn btn--primary" tabindex="0">
                {{ this.labels.value.buttonBook }}
              </button>

              <!-- Primary fields for the Security banner -->
              <input type="hidden" value="1" name="submitted" />
              <input
                type="hidden"
                name="arrivalDate"
                v-model="entryDateNormalised"
              />
              <input
                type="hidden"
                name="AirportVisitDetails_arrival_hour"
                v-model="entryTime"
              />
              <input
                type="hidden"
                name="SimpleTicket_numTickets"
                v-model="loungeAdults"
              />
            </form>
            <!-- commented out the Airport Services button for now as it might be needed at a later date -->
            <!-- <button
              class="btn btn--secondary"
              @click="redirectToAirportServices"
            >
              Airport services
            </button> -->
          </div>
        </div>
      </fieldset>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";
import dayjs from "dayjs";

const LOCALE_CONFIG_MAPPING = {
  "en-US": {
    currency: "$",
    inputFormat: "MM/dd/yyyy",
    displayFormat: "MM/DD/YYYY",
  },
  "en-EU": {
    currency: "€",
    inputFormat: "dd/MM/yyyy",
    displayFormat: "DD/MM/YYYY",
  },
  "en-GB": {
    currency: "£",
    inputFormat: "dd/MM/yyyy",
    displayFormat: "DD/MM/YYYY",
  },
  // add more mappings as needed
};

export default {
  name: "StickyBookingBarSecurityForm",
  mixins: [parkingForm],
  props: {
    securityPinnedBookingBarSettings: Object,
  },
  computed: {
    localeConfig() {
      // Return a default configuration if locale not found.
      return (
        LOCALE_CONFIG_MAPPING[
          this.securityPinnedBookingBarSettings.fields.globalLocale
        ] || LOCALE_CONFIG_MAPPING["en-US"]
      );
    },
    inputFormat() {
      return this.localeConfig.inputFormat;
    },
    displayFormat() {
      return this.localeConfig.displayFormat;
    },
    entryDateAndTime() {
      const [hours, minutes] = this.entryTime.split(":");
      return new Date(
        this.entryDate.getFullYear(),
        this.entryDate.getMonth(),
        this.entryDate.getDate(),
        hours,
        minutes
      );
    },
    entryDateNormalised: function () {
      return dayjs(this.entryDate).format(this.displayFormat);
    },
  },
  data() {
    return {
      arrivalDate: new Date(),
      entryDate: "",
      entryTime: "",
      loungeAdults: "1",
    };
  },
  methods: {
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
    redirectToAirportServices() {
      window.location.href = "/at-the-airport/airport-services/";
    },
    PBBSubmitSecurityForm(e) {
      e.target.submit();
      var minimumNotice =
        parseInt(
          this.securityPinnedBookingBarSettings.fields
            .aeroParkerParkingMinimumNotice
        ) || 0;
      var minimumTime = this.securityPinnedBookingBarSettings.fields.timezone
        ? new Date(
            this.securityPinnedBookingBarSettings.fields.timezone.currentTime
          )
        : new Date();
      minimumTime.setMinutes(minimumTime.getMinutes() + minimumNotice);
      if (
        this.entryDateAndTime < minimumTime ||
        this.exitDateAndTime < minimumTime
      ) {
        var timeValidationMessage = "";
        if (minimumNotice === 0) {
          timeValidationMessage = this.labels.value.validationTimeGeneric;
        } else if (minimumNotice % 60 === 0) {
          timeValidationMessage = this.labels.value.validationTimeHours.replace(
            "{0}",
            minimumNotice / 60
          );
        } else {
          timeValidationMessage =
            this.labels.value.validationTimeMinutes.replace(
              "{0}",
              minimumNotice
            );
        }
        alert(timeValidationMessage);
        e.preventDefault();
      } else if (this.exitDateAndTime <= this.entryDateAndTime) {
        alert(this.labels.value.validationTimeOrder);
        e.preventDefault();
      }
    },
  },
  components: {
    Select2,
    DatePicker,
  },
};
</script>
