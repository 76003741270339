<template>
  <section class="panel-padding"></section>

  <div class="content--timeline">
    <div class="content__sidebar">
      <aside class="sidebar">
        <ol>
          <li
            v-for="(item, index) in componentData.fields.timelineSections"
            :key="index"
          >
            <a>{{ item.fields.title }}</a>
          </li>
        </ol>
      </aside>
    </div>
    <div class="content__main">
      <div
        class="typeset timeline"
        v-for="(item, index) in componentData.fields.timelineSections"
        :key="index"
      >
        <div class="timeline__heading">
          <span class="timeline__number">{{ index + 1 }}</span>
          <h2>{{ item.fields.title }}</h2>
        </div>
        <div class="timeline__content">
          <template
            v-for="(component, index) in item.fields.timelinePanels"
            :key="index"
          >
            <component
              :is="component.system.contentType"
              :componentData="component"
              :selectedLanguage="selectedLanguage"
              :pageInfo="info"
            ></component>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FlightStatusComponent from "@/components/FlightStatusComponent.vue";
import TimelinePanel from "@/components/TimelinePanels/TimelinePanelBasic";
import TimelineTabbed from "@/components/TimelinePanels/TimelineTabbedPanel";
import TimelineImageBlock from "@/components/TimelinePanels/TimelineImageBlock.vue";
import TimelineImage from "@/components/TimelinePanels/TimelineImage";
import TimelineListBlockPanel from "@/components/TimelinePanels/TimelineListBlockPanel";
import TimelineSecurityPanel from "@/components/TimelinePanels/TimelineSecurityPanel";

/* eslint-disable vue/no-unused-components */
export default {
  name: "TimelineComponent",
  props: ["componentData", "flightNumber", "flightDate"],
  components: {
    FlightStatusComponent,
    TimelinePanel,
    TimelineImageBlock,
    TimelineImage,
    TimelineListBlockPanel,
    TimelineSecurityPanel,
    TimelineTabbed,
  },
  mounted() {},
  methods() {},
};
</script>

<style lang="scss" scoped>
.content--timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  max-width: 96rem;
  padding: 2rem;

  @include breakpoint($desktop-sml) {
    padding: 0;
    justify-content: flex-end;
    flex-direction: row-reverse;
    flex-shrink: 1;
  }
}
.content__main {
  padding-left: 0;
  width: 100%;
  height: auto;

  @include breakpoint($desktop-sml) {
    width: 65%;
  }
}
.content__sidebar {
  @include breakpoint($desktop-sml) {
    width: 35%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}
.sidebar {
  background: #f4fcfe;
  border: 1px solid rgba(0, 168, 225, 0.5);
  border-radius: 2px;
  width: 100%;
  height: auto;
  display: flex;
  justify-self: flex-end;
  padding: 3rem 1.5rem;

  @include breakpoint($desktop-sml) {
    max-width: 30rem;
  }

  ol {
    margin-left: 3rem;

    li {
      font-size: 1.8rem;
      display: list-item;
      color: rgba(0, 168, 225, 0.5);
      padding-bottom: 1rem;
    }

    a {
      line-height: 1.4;
      padding-left: 1rem;
    }
  }
}
.timeline {
  position: relative;

  @include breakpoint($desktop-sml) {
    max-width: 76rem;
  }
}
.timeline__heading {
  cursor: pointer;
  position: relative;
  margin: 0 !important;
  padding: 2rem 2rem 2rem 4.5rem;
  padding-left: 5rem;

  h2 {
    font-size: 3.6rem;
    font-weight: 600;
    color: $skin-colour-primary;
    font-weight: 400;
    margin-bottom: 0 !important;
  }
}

.timeline__content {
  padding: 0rem 0 2.5rem 5rem;

  p {
    padding: 3rem;
    color: white;
  }
}
.timeline-marker {
  position: relative;
}
.timeline__content .timeline-marker:before {
  display: block;
  position: absolute;
  top: -1rem;
  left: -4.5rem;
  background: $skin-colour-secondary;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  border: 7px solid $white;
  content: "";
  z-index: 100;
}
.timeline__number {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  background: $skin-colour-secondary;
  font-size: 2rem;
  border: 5px solid $white;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
