<template>
  <div class="index-sheet">
    <h2>Pages</h2>
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/content-page/">Content Page</router-link></li>
      <li><router-link to="/styleguide">Styleguide</router-link></li>
      <li><router-link to="/flight-status">Flight Status Page</router-link></li>
      <li><router-link to="/timeline-panels">Timeline Panels</router-link></li>
      <li><router-link to="/sitemap.html">SiteMap HTML</router-link></li>
    </ul>
    <h2>Modals (/components/modals/x)</h2>
    <ul>
      <li>
        <button @click="openModal('GeneralModal')">Open General modal</button>
      </li>
      <li>
        <button @click="openModal('WarningModal')">Open Warning modal</button>
      </li>
      <li>
        <button @click="openModal('EmergencyModal')">
          Open Emergency modal
        </button>
      </li>
      <li>
        <button @click="openModal('NewsLetterModal')">
          Open Newsletter modal
        </button>
      </li>
      <li>
        <button @click="openModal('WatchFlightModal')">
          Open Watch Flight modal
        </button>
      </li>
    </ul>

    <h2>Components (/components/x/)</h2>
    <div class="links" v-if="info.fields">
      <router-link
        v-for="(component, index) in info.fields.components"
        :key="index"
        :to="`/components/` + component.system.urlSegment"
      >
        {{ component.system.name }}
      </router-link>
    </div>

    <Modal
      :isOpen="isModalOpened"
      @modal-close="closeModal"
      :modalClass="(currentModal || '').toLowerCase()"
      ><component :is="currentModal"></component
    ></Modal>
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src
import Modal from "./Modal.vue";
import WarningModal from "@/components/Modals/Warning";
import GeneralModal from "@/components/Modals/General";
import EmergencyModal from "@/components/Modals/Emergency";
import NewsLetterModal from "@/components/Modals/NewsLetter";
import WatchFlightModal from "@/components/Modals/WatchFlight";

export default {
  name: "SiteHome",
  components: {
    Modal,
    WarningModal: WarningModal,
    GeneralModal: GeneralModal,
    EmergencyModal: EmergencyModal,
    NewsLetterModal: NewsLetterModal,
    WatchFlightModal: WatchFlightModal,
  },
  data() {
    return {
      info: [],
      isModalOpened: false,
      currentModal: null,
    };
  },
  methods: {
    openModal(currentModalArgs) {
      this.isModalOpened = true;
      this.currentModal = currentModalArgs;
    },
    closeModal() {
      this.isModalOpened = false;
      this.currentModal = null;
    },
  },
  mounted() {
    axios
      .get(
        "/dummy-contentpage.json" // This will be swapped out for the real API feed later
      )
      .then((response) => (this.info = response.data));
  },
};
</script>

<style scoped>
.index-sheet {
  max-width: 1160px;
  margin: 60px auto 0;
  font-size: 18px;
  padding: 0 20px;
  text-align: left;
}
ul {
  margin: 4rem 0;
}
ul li {
  margin-bottom: 20px;
}
div a {
  display: block;
  margin-bottom: 20px;
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 28px;
}
h1,
h2 {
  margin-bottom: 30px;
}
code {
  background: black;
  color: white;
  padding: 3px;
}
</style>
