<template>
  <section class="center panel-padding">
    <header>
      <div class="inner inner--thin">
        <h2 class="heading centered">{{ componentData.fields.title }}</h2>
      </div>
    </header>
    <div class="inner inner--thin">
      <div class="grid">
        <div
          class="grid__third"
          v-for="destination in destinations"
          :key="destination.id"
        >
          <a
            :href="destination.url"
            class="destination-item destination-item--alt loaded"
          >
            <div>
              <h3 class="destination-item__title">
                {{ destination.title }}
              </h3>
              <p class="destination-item__copy">
                {{ destination.description }}
              </p>
            </div>
            <img
              :alt="destination.title"
              class="destination-item__image"
              :src="destination.destinationImageUrl"
              style=""
            />
          </a>
        </div>
      </div>
    </div>

    <!-- Destination blocks container -->
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "FeaturedDestinationsList",
  props: ["selectedLanguage", "componentData"],
  data() {
    return {
      destinations: [],
      webpage: "",
      options: "",
    };
  },
  mounted() {
    this.loadDestinations();
  },
  watch: {
    selectedLanguage: "loadDestinations",
  },
  methods: {
    loadDestinations() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/DestinationApi/GetFeaturedDestinationsLite?url=${this.webpage}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.destinations = response.data.destinations;
          console.log(this.destinations);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.centered {
  text-align: center;
}
.destination-item {
  position: relative;
  overflow: hidden;
  height: 250px;
  margin-bottom: 15px;
  display: block;

  &.loaded {
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }

    &:hover::before,
    &:focus::before {
      background: rgba(0, 0, 0, 0.5);
      transition: background 0.25s linear;
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    // min-width: 100%;
    // height: auto;
    max-width: none;

    &.small {
      @include breakpoint($tablet-sml) {
        min-width: 0;
        height: 110%;
      }
    }
  }

  @include breakpoint($tablet-sml) {
    height: 300px;
    margin-bottom: 20px;
  }

  @include breakpoint($tablet) {
    height: 225px;
  }

  @include breakpoint($desktop-sml) {
    height: 300px;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 15px;

    @include breakpoint($tablet) {
      padding: 15px 5px;
    }

    @include breakpoint($desktop) {
      padding: 15px;
    }
  }

  &--alt {
    height: 350px;

    @include breakpoint($tablet-sml) {
      height: 450px;
    }

    @include breakpoint($desktop) {
      height: 620px;
    }

    > div {
      text-align: center;
      top: 40%;
      transform: translate(-50%, 0);
    }
  }

  &--large {
    height: 400px;

    @include breakpoint($tablet-sml) {
      height: 620px;
    }

    @include breakpoint($tablet) {
      height: 470px;
    }

    @include breakpoint($desktop-sml) {
      height: 620px;
    }
  }

  &--thin {
    @include breakpoint($tablet-sml) {
      height: 620px;
    }

    @include breakpoint($tablet) {
      height: 470px;
    }

    @include breakpoint($desktop-sml) {
      height: 620px;
    }
  }

  &__subtitle {
    font-size: 1.4rem;
    font-weight: $light;
    margin-bottom: 0;
    color: $white;
    line-height: 1.1;
  }

  &__title {
    font-size: 3.2rem;
    color: $white;
    line-height: 1.1;
    font-weight: $bold;
    margin-bottom: 20px;

    @include breakpoint($tablet) {
      font-size: 2.5rem;
    }

    @include breakpoint($desktop-sml) {
      font-size: 3.2rem;
    }
  }

  &__copy {
    color: $white;
    margin: 0;
    font-size: 1.4rem;
  }

  // &__intro {
  //     font-size: 1.4rem;
  //     line-height: 1.5;
  //     font-weight: $reg;
  //     margin: 0;
  //     color: $white;
  // }

  // &__cta {
  //     font-size: 1.4rem;
  //     font-weight: $reg;
  //     text-transform: uppercase;
  //     margin-top: 30px;
  //     display: block;
  //     color: $skin-colour-primary;

  //     .destination-item--alt & {
  //         position: absolute;
  //         z-index: 2;
  //         left: 20px;
  //         bottom: 20px;
  //         margin: 0;
  //         color: $white;
  //     }
  // }

  // &--alt {
  //     >div {
  //         text-align: center;
  //         top: 40%;
  //         transform: translate(-50%, 0);
  //     }
  // }
}
</style>
