<template>
  <section class="carrental-widget">
    <div class="form form--inline">
      <fieldset>
        <div class="grid">
          <div class="grid__third">
            <label for="pbb_pickUpLocations">Pick-up location</label>
            <Select2
              name="pbb_pickUpLocations"
              :options="pbb_airports"
              :settings="{
                placeholder: 'Please select',
              }"
              v-model="pbb_pickUpLocations"
            />
          </div>

          <div class="grid__sixth">
            <label for="pbb_pickUpDate">Pick-up date</label>
            <DatePicker
              v-model="pbb_pickUpDate"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue || this.labels.value.labelDate"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>

          <div class="grid__sixth date-time">
            <div class="col-time-hours">
              <label for="pbb_pickUpHours"
                ><span class="hiddenFromView">Pick-up</span> time
                <span class="hiddenFromView">hours</span></label
              >
              <Select2
                name="pbb_pickUpHours"
                class="selectbox-time-half"
                :options="[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]"
                v-model="pbb_pickUpHours"
              />
            </div>
            <div class="col-time-mins">
              <label for="pbb_pickUpMinutes"
                ><span class="hiddenFromView">Pick-up time minutes</span></label
              >

              <Select2
                class="selectbox-time-half"
                name="pbb_pickUpMinutes"
                :options="['00', '15', '30', '45']"
                v-model="pbb_pickUpMinutes"
              />
            </div>
          </div>

          <div class="grid__sixth">
            <label for="pbb_dropOffDates">Drop-off date</label>
            <DatePicker
              name="pbb_dropOffDates"
              v-model="pbb_dropOffDates"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue || this.labels.value.labelDate"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>

          <div class="grid__sixth date-time">
            <div class="col-time-hours">
              <label for="dropOffHour"
                ><span class="hiddenFromView">Drop-off</span> time
                <span class="hiddenFromView">hours</span></label
              >
              <Select2
                name="pbb_dropOffHours"
                :options="[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]"
                v-model="pbb_dropOffHours"
              />
            </div>
            <div class="col-time-mins">
              <label for="dropOffMinute"
                ><span class="hiddenFromView"
                  >Drop-off time minutes</span
                ></label
              >

              <Select2
                name="pbb_dropOffMinutes"
                :options="['00', '15', '30', '45']"
                v-model="pbb_dropOffMinutes"
              />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="buttons">
        <div class="grid">
          <div class="grid__half">
            <button
              @click="pbb_getURLPOST()"
              class="btn btn--primary"
              tabindex="0"
            >
              Book Car Hire
            </button>
            <!-- commented out the Airport Services button for now as it might be needed at a later date -->
            <!-- <button
              class="btn btn--secondary"
              @click="redirectToAirportServices"
            >
              Airport services
            </button> -->
          </div>
        </div>
      </fieldset>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import axios from "axios";
import dayjs from "dayjs";
export default {
  name: "StickyBookingBarCarForm",
  inject: ["publicSiteSettings", "labels"],
  data() {
    return {
      pbb_pickUpLocations: "",
      pbb_pickUpDate: "",
      pbb_pickUpHours: "00",
      pbb_pickUpMinutes: "00",
      pbb_dropOffDates: null,
      pbb_dropOffHours: "00",
      pbb_dropOffMinutes: "00",
      pbb_airports: [],
      pbb_vehiclesURL: "",
      pbb_displayFormat:
        this.publicSiteSettings.value.fields.globalLocale === "en-US"
          ? "DD-MM-YYYY"
          : "DD-MM-YYYY",
      pbb_timeFormat:
        this.publicSiteSettings.value.fields.globalLocale === "en-US"
          ? "H:mm:ss"
          : "H:mm:ss",
      pbb_displayFormatReverse: "YYYY-MM-DD",
    };
  },
  computed: {},
  components: {
    Select2,
    DatePicker,
  },
  mounted() {
    this.pbb_loadLocations();
  },
  watch: {
    selectedLanguage: "loadLocations",
  },
  methods: {
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
    redirectToAirportServices() {
      window.location.href = "/at-the-airport/airport-services/";
    },
    pbb_loadLocations() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/AirportsApi/GetAirportsLite?url=/airports/&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.pbb_airports = response.data.airports;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pbb_formatDateTime(pbb_incomingDate, pbb_returnType) {
      let pbb_returnFormat = this.pbb_displayFormat + " " + this.pbb_timeFormat;
      switch (pbb_returnType) {
        case "date":
          pbb_returnFormat = this.pbb_displayFormat;
          break;
        case "time":
          pbb_returnFormat = this.pbb_timeFormat;
          break;
        case "dateReverse":
          pbb_returnFormat = this.pbb_displayFormatReverse;
          break;
      }
      return dayjs(pbb_incomingDate).format(pbb_returnFormat);
    },
    pbb_getURLPOST() {
      const pbb_pickUpTimeString =
        this.pbb_pickUpHours + ":" + this.pbb_pickUpMinutes + ":00";
      const pbb_dropOffTimeString =
        this.pbb_dropOffHours + ":" + this.pbb_dropOffMinutes + ":00";
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/CarTrawlerApi/GetCarsAvailable`;
      console.log("Pickup location - " + this.pbb_pickUpLocations);
      axios({
        method: "post",
        url: apiUrl,
        headers: {},
        data: {
          pickUpLocation: this.pbb_pickUpLocations,
          dropOffLocation: this.pbb_pickUpLocations,
          pickUpDate: this.pbb_formatDateTime(this.pbb_pickUpDate, "date"),
          pickUpTime: pbb_pickUpTimeString,
          returnDate: this.pbb_formatDateTime(this.pbb_dropOffDates, "date"),
          returnTime: pbb_dropOffTimeString,
          iataCode: process.env.VUE_APP_IATACODE,
          cultureCode: this.selectedLanguage,
        },
      })
        .then((response) => {
          this.pbb_vehiclesURL = response.data;
          window.open(this.pbb_vehiclesURL);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
