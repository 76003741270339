<template>
  <div class="timeline-panel timeline-marker">
    <h3>
      <span>
        <img :src="componentData.fields.icon.fields.umbracoFile.src" />
      </span>
      {{ componentData.fields.title }}
    </h3>
    <div v-html="componentData.fields.body"></div>
    <a
      :href="componentData.fields.link.url"
      v-if="
        componentData.fields.link != null &&
        componentData.fields.link.url.length > 0
      "
      class="btn btn--secondary"
      >{{ componentData.fields.link.name }}</a
    >
  </div>
</template>

<script>
export default {
  name: "TimelinePanelBasic",
  props: ["componentData"],
  components: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.timeline-panel {
  width: 100%;
  padding: 3rem;
  border: 1px solid $skin-colour-primary;
  margin-bottom: 5rem;

  h3 {
    position: relative;
    align-items: center;
    display: flex;

    span {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: 0 1rem 0 0;
      border-radius: 50%;
      background: #213368;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
    }
  }
}
</style>
