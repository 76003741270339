<template>
  <footer class="footer">
    <div class="inner">
      <div class="grid">
        <div
          class="footer__col-quarter"
          v-for="item in publicSiteSettings.fields.footerNavigation"
          :key="item.id"
        >
          <h4 class="heading">{{ item.fields.linkGroupTitle }}</h4>
          <ul>
            <li v-for="link in item.fields.linkGroupSublinks" :key="link.id">
              <a :href="link.url" :title="link.name" :target="link.target">
                {{ link.name }}
              </a>
            </li>
          </ul>
        </div>
        <div v-if="publicSiteSettings.fields.cookieProConsentId">
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">
            Cookie Settings
          </button>
        </div>
      </div>
      <!--.grid -->

      <div
        class="footer__featured grid"
        v-if="
          publicSiteSettings.fields.footerFeatures &&
          publicSiteSettings.fields.footerFeatures.length
        "
      >
        <div
          class="footer__col-half"
          v-for="feature in publicSiteSettings.fields.footerFeatures"
          :key="feature.id"
        >
          <div class="footer__featured__item">
            <div class="footer__featured__item__img">
              <img
                :src="feature.fields.image?.fields.umbracoFile?.src"
                :alt="feature.fields.image?.system?.name"
              />
            </div>
            <div class="footer__featured__item__content">
              <h4 class="heading">{{ feature.fields.title }}</h4>
              <p>
                {{ feature.fields.subtitle }}
              </p>
              <a
                :href="feature.fields.link?.url ?? '#'"
                :title="feature.fields.link?.name"
                :target="feature.fields.link?.target ?? '_self'"
                class="btn btn--keyline btn--small"
                v-if="feature.fields.link"
              >
                {{ feature.fields.link?.name }}
              </a>
            </div>
          </div>
          <!--/.magazine -->
        </div>
      </div>
      <!--/.footer__magazines grid -->
    </div>
    <div class="footer__bottom">
      <div class="inner cf">
        <ul class="social-links">
          <li
            v-for="item in publicSiteSettings.fields.socialNetworks"
            :key="item.id"
          >
            <a :href="item.fields.url">
              <SvgIcon :name="item.fields.identifier" /><span class="sr-only">
                {{ item.fields.title }}
              </span>
            </a>
          </li>
        </ul>
        <img
          :src="publicSiteSettings.fields.footerLogo?.fields.umbracoFile?.src"
          :alt="publicSiteSettings.fields.footerLogo?.fields?.alt"
          class="footer__logo"
          v-if="publicSiteSettings.fields.footerLogo"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import SvgIcon from "@/components/Icon";
export default {
  name: "SiteFooter",
  props: ["publicSiteSettings"],
  components: {
    SvgIcon,
  },
};
</script>

<style lang="scss">
.footer {
  background: $footer-bg;
  color: $footer-color;
  padding: 6rem 0 3rem;

  .heading {
    color: $footer-color;
  }

  &__col-quarter {
    lost-column: 1/1;
    margin-bottom: 4.5rem;

    .heading {
      font-size: 1.6rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
      font-weight: $reg;
    }

    @include breakpoint($tablet-sml) {
      lost-column: 1/2;
    }

    @include breakpoint($desktop-sml) {
      lost-column: 1/4;
      margin-bottom: 6rem;
    }
  }

  &__col-half {
    lost-column: 1/1;
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include breakpoint($desktop-sml) {
      lost-column: 1/2;
      margin-bottom: 0;
    }

    .heading {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      font-weight: $reg;
      font-family: $lato;

      @include breakpoint($desktop-sml) {
        font-size: 2.8rem;
      }
    }
  }

  &__bottom {
    padding: 3rem 0 0;

    @include breakpoint($tablet-sml) {
      padding: 4rem 0 0;
    }
  }

  &__logo {
    display: block;
    // text-indent: -9999px;

    margin: 0 auto;
    max-height: 37px;
    // background-image: url("/images/lax-logo.png") !important;
    // background-size: contain;

    @include breakpoint($tablet-sml) {
      display: inline-block;
      float: right;
      margin: -1rem 0 0 0;
    }
  }

  &__featured {
    padding: 6rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &__item {
      display: table;
      width: 100%;

      &__img {
        width: 110px;
        padding-right: 2rem;
        @include breakpoint($tablet) {
          width: 140px;
          padding-right: 3rem;
        }
      }

      &__img,
      &__content {
        display: table-cell;
        vertical-align: middle;
      }

      .btn--keyline {
        display: inline-block;
        margin-right: 2rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  li {
    display: block;
    margin-bottom: 2rem;

    a {
      font-size: 1.6rem;
      line-height: 1.3;
      display: block;
      text-decoration: none;

      @include breakpoint($desktop) {
        font-size: 1.8rem;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  a {
    color: $footer-color-trans;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.4;
    color: $footer-color-trans;

    @include breakpoint($desktop) {
      font-size: 1.5rem;
    }
  }

  .btn {
    color: $footer-color;
  }

  .btn--keyline {
    border-color: $white;

    .icon-download {
      top: -3px;
      position: relative;
    }

    &:hover,
    &:focus {
      background: $white;
      color: $footer-bg !important;
      g {
        fill: $footer-bg;
      }
    }
  }

  .social-links {
    margin-bottom: 3rem;
    text-align: center;

    @include breakpoint($tablet-sml) {
      float: left;
    }

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 1rem 0;

      a {
        position: relative;
        display: block;
        width: 3rem;
        height: 3rem;
        // text-indent: -99999px;
        // padding: 0.5rem;
        // background-position: 50% 50%;

        svg {
          width: 2.5rem;
          height: 2.5rem;
          top: 50%;
          position: relative;
          transform: translateY(-50%);
        }
      }
    }
  }
}

#ot-sdk-btn.ot-sdk-show-settings {
  color: rgba(255, 255, 255, 0.5) !important;
  border: none !important;
  padding: 0 0 !important;
  font-size: 18px !important;
  line-height: 1em !important;
  word-wrap: unset !important;
  margin-left: -10px !important;
  font-family: Montserrat, sans-serif !important;
}
#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
  background: unset !important;
  text-decoration: underline !important;
}
</style>
