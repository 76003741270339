<template>
  <section class="panel-padding">
    <div class="inner">
      <div class="destination-tags-container">
        <h2 class="heading">
          {{ componentData.fields.title }}
        </h2>

        <button
          class="btn btn--keyline destination-filter"
          @click="toggleFilterBlock()"
        >
          Filter your results <span>{{ destinationFilterSign }}</span>
        </button>

        <div
          class="destination-tags"
          :class="{
            'destination-tags--active': activeFilterBlock,
          }"
        >
          <button
            v-for="tag in tags"
            :key="tag.id"
            class="btn btn--secondary btn--small btn--tag"
            :class="{ 'active-tag': tag.destinationTagName === selectedTag }"
            @click="toggleFilter(tag.destinationTagName)"
          >
            {{ tag.destinationTagName }}
          </button>
        </div>
      </div>

      <div
        v-for="(block, index) in filteredDestinations()"
        :key="block.id"
        :class="`destination-block-container ${getDestinationBlockClass(
          index
        )}`"
      >
        <div
          v-for="destination in block"
          :key="destination.id"
          class="destination-block"
        >
          <a :href="destination.url" class="destination-link">
            <div class="destination-content">
              <h3 class="destination-content__subtitle">
                {{ destination.country }}
              </h3>
              <h4 class="destination-content__title">
                {{ destination.cityName }}
              </h4>
            </div>
            <img
              class="destination-image"
              :src="destination.destinationImageUrl"
              :alt="destination.cityName"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "DestinationList",
  props: ["selectedLanguage", "componentData"],
  data() {
    return {
      destinations: [],
      webpage: "",
      tags: [],
      options: "",
      activeFilterBlock: false,
      destinationFilterSign: "+",
      selectedTag: null,
    };
  },
  mounted() {
    this.loadDestinations();
  },
  watch: {
    selectedLanguage: "loadDestinations",
  },
  methods: {
    getDestinationBlockClass(index) {
      const cycleLength = 3;
      const cycle = index % cycleLength;
      return "destination-block-container-type" + (cycle + 1);
    },
    loadDestinations() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/DestinationApi/GetDestinationsLite?url=${this.webpage}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.destinations = response.data.destinations;
          this.tags = response.data.destinationTags;
          this.toggleFilter();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filteredDestinations() {
      const cycleLength = 5;
      let _destinationBlocks = [];
      let _dest = this.destinations;

      if (this.selectedTag) {
        _dest = this.destinations.filter((destination) => {
          return destination.destinationTags.includes(this.selectedTag);
        });
      } else {
        _dest = this.destinations;
      }

      for (let i = 0; i < _dest.length; i += cycleLength) {
        _destinationBlocks.push(_dest.slice(i, i + cycleLength));
      }
      return _destinationBlocks;
    },
    toggleFilterBlock() {
      this.activeFilterBlock = !this.activeFilterBlock;
      if (this.activeFilterBlock) {
        this.destinationFilterSign = "+";
      } else {
        this.destinationFilterSign = "-";
      }
    },
    toggleFilter(tag = null) {
      if (this.selectedTag === tag) {
        this.selectedTag = null;
      } else {
        this.selectedTag = tag;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.destination-tags-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 30px auto;

  .heading {
    text-align: center;
  }
}
.btn--tag {
  background: white !important;
  color: $btn-primary !important;

  &:hover {
    background: $btn-primary !important;
    color: #fff !important;
  }

  &:focus {
    background: $btn-primary !important;
    color: #fff !important;
  }
  &.active-tag {
    background: $btn-primary !important;
  }
}
.destination-filter {
  width: 80%;

  @include breakpoint($tablet) {
    display: none;
  }
}
.destination-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  display: none;

  &--active {
    display: flex;
  }

  @include breakpoint($tablet) {
    display: flex;
  }
}
.destination-block-container {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: repeat(4, 200px);
  margin-bottom: 20px;

  @include breakpoint($tablet) {
    grid-template-rows: repeat(2, minmax(100px, 170px));
  }
  @include breakpoint($desktop) {
    grid-template-rows: repeat(2, minmax(100px, 270px));
  }
}
.destination-block-container-type1 {
  grid-template-columns: 1fr 1fr;

  @include breakpoint($tablet) {
    grid-template-columns: 50% repeat(2, minmax(100px, 270px));
  }
  .destination-block:first-child {
    grid-area: 1 / 1 / 3 / 3;

    @include breakpoint($tablet) {
      grid-area: 1 / 1 / 3 / 2;
    }
  }
}
.destination-block-container-type2 {
  grid-template-columns: 1fr 1fr;

  @include breakpoint($tablet) {
    grid-template-columns: minmax(100px, 270px) 50% minmax(100px, 270px);
  }
  .destination-block:nth-child(3) {
    grid-area: 2 / 1 / 4 / 3;

    @include breakpoint($tablet) {
      grid-area: 1 / 2 / 3 / 3;
    }
  }
}
.destination-block-container-type3 {
  grid-template-columns: 1fr 1fr;

  @include breakpoint($tablet) {
    grid-template-columns: repeat(2, minmax(100px, 270px)) 50%;
  }
  .destination-block:last-child {
    grid-area: 3 / 1 / 5 / 3;

    @include breakpoint($tablet) {
      grid-area: 1 / 3 / 3 / 4;
    }
  }
}
.destination-block {
  border-radius: 12px;
  overflow: hidden;
}
.destination-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.destination-content__subtitle {
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  text-align: center;
  line-height: 1;
  margin-bottom: 0;
}
.destination-content__title {
  font-size: 2rem;
  @include breakpoint($desktop-sml) {
    font-size: 3.2rem;
  }
  color: #fff;
  text-align: center;
  margin-bottom: 0;
}
.destination-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.destination-link {
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;

  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    z-index: 1;
  }

  &:hover:before,
  &:focus:before {
    background: rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.25s linear;
    -o-transition: background 0.25s linear;
    transition: background 0.25s linear;
  }
}
</style>
